import React, { useEffect, useRef, useState } from 'react'

import {
  type Tracking,
  mapTrackingKeysToDataAttributes,
} from '@cais-group/equity/util/tracking-utils'

type DisclosureContextType = {
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
}

const DisclosureContext = React.createContext<DisclosureContextType>({
  isOpen: false,
})

type DisclosureProps = Tracking &
  React.ComponentPropsWithoutRef<'details'> & {
    onChangeOpen?: (isOpen: boolean) => void
    defaultIsOpen?: boolean
    title?: string
    testId?: string
  }

/**
 * A simple, accessible foundation for building custom UIs that show and hide content, like toggleable sidebar contents or accordion panels.
 * @private
 * The main disclosure component.
 */
export const Disclosure = ({
  open,
  onChangeOpen,
  defaultIsOpen,
  title,
  tracking,
  testId,
  ...props
}: DisclosureProps) => {
  const isOpen = open ?? defaultIsOpen ?? false
  const detailsRef = useRef<HTMLDetailsElement>(null)
  useEffect(() => {
    if (detailsRef.current) {
      detailsRef.current.open = Boolean(isOpen)
    }
  }, [isOpen])
  const [openStateForTracking, setOpenStateForTracking] = useState(
    isOpen || false
  )

  return (
    <DisclosureContext.Provider
      value={{
        isOpen: isOpen,
        setIsOpen: typeof open === 'undefined' ? undefined : onChangeOpen,
      }}
    >
      <details
        data-testid={testId}
        ref={detailsRef}
        open={isOpen}
        onToggle={() => {
          setOpenStateForTracking(!openStateForTracking)
        }}
        {...mapTrackingKeysToDataAttributes({
          status: 'on',
          click_type: 'Information Expansion',
          item_name: `${title} ${
            // Note: the value is inverted because it's the state it will next transition to when toggled
            openStateForTracking === true ? 'Collapsed' : 'Expanded'
          }`,
          ...tracking,
        })}
        {...props}
      />
    </DisclosureContext.Provider>
  )
}

/**
 * @private
 * The trigger component that toggles a Disclosure.
 */
export const DisclosureButton = ({
  disabled,
  onClick,
  ...props
}: React.ComponentPropsWithoutRef<'summary'> & { disabled?: boolean }) => {
  const { isOpen, setIsOpen } = React.useContext(DisclosureContext)

  return (
    <summary
      {...props}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault()
          return
        }

        if (setIsOpen) {
          e.preventDefault()
          setIsOpen(!isOpen)
        }

        onClick?.(e)
      }}
      onKeyDown={
        setIsOpen &&
        ((e) => {
          // When the component is controlled, we must explicitly handle keyboard events.
          if (['Enter', ' '].includes(e.key)) {
            e.preventDefault()
            setIsOpen(!isOpen)
          }
        })
      }
    />
  )
}
