import { useMediaQuery, useTheme } from '@mui/material'
import cx from 'classnames'
import React, { useCallback } from 'react'

import {
  CloudinaryImage,
  cloudinaryUrl,
} from '@cais-group/shared/ui/cloudinary-image'
import type { ComponentImage } from '@cais-group/shared/util/graphql/mfe-contentful'

import Lightbox from '../shared-ui-lightbox'
import { useLightbox } from '../use-lightbox'

const FALLBACK_HERO_IMAGE_ID = 'pws/heroes/cais-live-hero-light_bx7whq'

const getOrientation = (width: number, height: number) => {
  if (width > height) {
    return 'landscape'
  }
  if (height > width) {
    return 'portrait'
  }
  return 'square'
}
export const getImageData = (
  image: NonNullable<ContentfulLightboxWrapperProps['cloudinaryImage']>[0],
  isTablet: boolean
) => {
  let lightBoxSrc
  const public_id = image.public_id || FALLBACK_HERO_IMAGE_ID
  const orientation = getOrientation(image.width, image.height)

  const aspectRatio = (image.width ?? 1) / (image.height ?? 1)

  const common = 'q_auto:best,c_fit'
  let width = Math.floor(window.innerWidth * 0.9),
    height = Math.floor(width / aspectRatio)

  if (orientation === 'landscape' && !isTablet) {
    lightBoxSrc = cloudinaryUrl(public_id, {
      transform: `${common},w_${width},h_${height}`,
    })
  } else if (orientation === 'landscape' && isTablet) {
    lightBoxSrc = cloudinaryUrl(public_id, {
      transform: `${common},w_auto,c_scale`,
    })
  } else if (orientation === 'portrait') {
    height = Math.floor(window.innerHeight * 0.8)
    width = Math.floor(height * aspectRatio)

    lightBoxSrc = cloudinaryUrl(public_id, {
      transform: `${common},w_${width}`,
    })
  } else {
    lightBoxSrc = cloudinaryUrl(public_id, {
      transform: common,
    })
  }

  return { lightBoxSrc, orientation, height, width }
}
export type ContentfulLightboxWrapperProps = {
  cloudinaryImage?: Array<{ public_id: string; width: number; height: number }>
  name?: ComponentImage['name']
  title?: React.ReactNode
}
export const ContentfulLightboxWrapper = (
  props: ContentfulLightboxWrapperProps
) => {
  const lightBoxControl = useLightbox()
  const [imageData, setImageData] = React.useState({
    lightBoxSrc: '',
    orientation: 'landscape',
    width: 0,
    height: 0,
  })
  const { cloudinaryImage, name, ...rest } = props
  const [isLoading, setIsLoading] = lightBoxControl.loadingState

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const image = cloudinaryImage?.[0]

  const resizeRef = useCallback(
    (lightboxResizeWrapper: HTMLDivElement) => {
      const updateMeasurements = () => {
        if (lightboxResizeWrapper?.clientHeight !== undefined && image) {
          setImageData(getImageData(image, isTablet))
        }
      }

      if (lightboxResizeWrapper) {
        const resizeObserver = new ResizeObserver(updateMeasurements)
        resizeObserver.observe(lightboxResizeWrapper)
        window.addEventListener('resize', updateMeasurements)
      }
    },
    [image, isTablet]
  )

  if (!image) {
    return null
  }

  const { lightBoxSrc, orientation, width } =
    imageData || getImageData(image, isTablet)

  return (
    <Lightbox
      control={lightBoxControl}
      width={width}
      expandedOk={!isMobile}
      alignment={orientation === 'portrait' ? 'center' : undefined}
      isLoading={isLoading}
      resizeRef={resizeRef}
      {...rest}
    >
      <CloudinaryImage
        src={lightBoxControl.isOpen ? lightBoxSrc : image.public_id}
        alt={`${lightBoxControl.isOpen ? 'Expanded-' : 'Expandable-'}${
          name || ''
        }`}
        layout="fullWidth"
        className={
          lightBoxControl.isOpen
            ? cx('max-h-[80vh]', {
                'h-[80vh]': orientation === 'portrait',
              })
            : ''
        }
        background="auto"
        priority
        onLoad={(e) => {
          if (e.target instanceof HTMLImageElement) {
            if (e.target.src.includes('q_auto:best')) {
              setIsLoading(false)
            }
          }
        }}
        tabIndex={0}
      />
    </Lightbox>
  )
}
