import cx from 'classnames'
import { map, pipe, take } from 'remeda'
import { match, P } from 'ts-pattern'

import { Icon } from '@cais-group/equity/atoms/icon'
import {
  Color,
  ContrastColor,
  colors,
  contrastColors,
} from '@cais-group/equity/particles/colors'

export type AvatarProps = {
  /** The size of the avatar. */
  size?: 'small' | 'regular' | 'large'
  /** It shows the avatar as a circle and 2 letters for users, a square and one letter for groups. */
  type?: 'user' | 'group'
  /** The name of the user/team/firm. If not provided, it renders an icon based on the avatar type. */
  children?: string
  testId?: string
  /** Whether the avatar is loading or not. */
  loading?: boolean
}

const availableAvatarColors = Object.keys(colors).filter(
  (key) => key !== 'eq-color-neutral-0'
) as Color[]

/**
 * Returns a hashed integer value that can be used to select a unique colour based on the input string.
 * @param input - The string to be hashed.
 * @returns A hashed integer value.
 * @example
 * ```
 * hashString('Andrew Bestbier');
 * // returns 65110100114
 * ```
 */
const hashString = (input: string) =>
  parseInt(
    input
      .split('')
      .map((char) => char.charCodeAt(0).toString())
      .join('')
  )

const transformGroupName = (value: string) => value[0]

/**
 * This function takes in a string value, which is typically the user's name, and transforms it into a string containing the first two initials of the name.
 * It does this by splitting the name into an array of words, taking the first character of each word, and then returning only the first two characters. The function uses the remeda "pipe" function to compose these operations.
 *
 * @param value - The user name
 * @returns An acronym representing the user
 */
const transformUserName = (value: string) =>
  pipe(
    value.split(' '),
    map((value) => value.charAt(0)),
    take(2)
  )

/**
 * A component that displays an avatar image or icon with an optional label.
 *
 * Avatar components are used throughout CAIS to represent a user or group.
 * These avatars are graphical representations of their respective entities consisting of a shortened acronym or icon.
 */
export const Avatar = ({
  children,
  size = 'regular',
  type = 'user',
  testId,
  loading = false,
}: AvatarProps) => {
  /**
   * Determine the background color of the avatar based on the children prop.
   * If children are provided, use a color from the avatarColors array based on a generated hash of the children string.
   * This function ensures that colors are deterministic for each entity name but, within alphabetically sorted tables, not all
   * users will have the same color.
   */
  const avatarColors: { bg: Color; fg: ContrastColor } = match({
    children,
    loading,
  })
    .with(
      { loading: true },
      () =>
        ({ bg: 'eq-color-neutral-200', fg: 'eq-color-neutral-200-c' } as const)
    )
    .with({ children: P.not(P.nullish) }, ({ children }) => {
      const bg =
        availableAvatarColors[
          hashString(children) % availableAvatarColors.length
        ]
      const fg = `${bg}-c` as ContrastColor
      return { bg, fg }
    })
    .otherwise(
      () =>
        ({ bg: 'eq-color-neutral-600', fg: 'eq-color-neutral-600-c' } as const)
    )

  const avatarContainerClasses = cx(
    'flex items-center justify-center shrink-0',
    {
      'rounded-full': type === 'user',
      'size-24': size === 'small',
      'size-32': size === 'regular',
      'size-[64px]': size === 'large',
      'animate-pulse': loading,
    }
  )

  // The Avatar label only uses uppercase letters which themselves only use space above the baseline
  // so it feels a little bit off center. To compensate it we apply a top padding of 1px.
  const avatarLabelClasses = cx('flex items-center justify-center pt-[1px]', {
    'caption-strong': size === 'small',
    'small-strong': size === 'regular',
    'headline-m-strong': size === 'large',
  })

  return (
    <div
      style={{
        backgroundColor: colors[avatarColors.bg],
        color: contrastColors[avatarColors.fg],
      }}
      className={avatarContainerClasses}
    >
      <div className={avatarLabelClasses} data-testid={testId}>
        {match({ children, type, loading })
          .with({ children: P.nullish, loading: false }, () => (
            <Icon
              type={type === 'user' ? 'User' : 'Teams'}
              color={avatarColors.fg}
              size={size}
            />
          ))
          .with(
            { type: 'user', children: P.not(P.nullish), loading: false },
            ({ children }) => transformUserName(children)
          )
          .with(
            { type: 'group', children: P.not(P.nullish), loading: false },
            ({ children }) => transformGroupName(children)
          )
          .otherwise(() => null)}
      </div>
    </div>
  )
}
