// The prefix for data attributes used on HTML elements for the properties to be sent to Segment
export const TRACKING_PREFIX = 'data-track'

export type ClickType =
  | 'Button'
  | 'Content'
  | 'Download'
  | 'Information Expansion'
  | 'Link'
  | 'Scroll'
  | 'Tab Navigation'
  | 'Dropdown'
  | 'Toggle'
  | 'Checkbox'

export type TrackingProps = {
  click_type?: ClickType
  content_owner?: string
  content_type?: string
  item_name?: string
  section?: string
  sub_section?: string
  /** If the link will be tracked. Passing "status: off" will exclude the link from tracking  */
  status?: 'on' | 'off'
  // todo: types need a refactor to allow extending tracking props? Seems to be half implemented?
  fund_id?: string
}

export type Tracking = { tracking?: TrackingProps }

export type AllowedTrackingKeys = keyof TrackingProps

type AllowedTrackingConfigItem = {
  name: AllowedTrackingKeys
  mergeable: boolean
}

export const allowedTrackingConfig: Readonly<AllowedTrackingConfigItem[]> = [
  { name: 'click_type', mergeable: false },
  { name: 'content_type', mergeable: false },
  { name: 'section', mergeable: false },
  { name: 'sub_section', mergeable: false },
  { name: 'item_name', mergeable: true },
  { name: 'content_owner', mergeable: false },
  { name: 'fund_id', mergeable: false },
] as const

export type TrackingPropsExtended<T> = TrackingProps & T

export type TrackingAttributes = {
  [TRACKING_PREFIX]?: boolean
} & {
  [K in keyof TrackingProps as `${typeof TRACKING_PREFIX}-${K}`]: TrackingProps[K]
}
