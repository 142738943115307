import cx from 'classnames'
import { ReactNode } from 'react'

import { Icon } from '@cais-group/equity/atoms/icon'
import { IconType } from '@cais-group/equity/particles/icons'

type Variant = 'neutral' | 'error' | 'info' | 'warning'

const getDimensions = (size: Size) => {
  const sizeDimensionsMap: Record<Size, string> = {
    large: 'h-56 w-56',
    small: 'h-24 w-24',
  }
  return sizeDimensionsMap[size]
}

const getBackground = (variant: Variant, size: Size) => {
  const backgroundMap: Record<Variant, string> = {
    neutral: 'bg-neutral-600',
    error: 'bg-error-600',
    warning: 'bg-warning-600',
    info: 'bg-primary-600',
  }
  if (size === 'large') {
    return backgroundMap[variant]
  }
  return ''
}

export type Size = 'large' | 'small'

export type ErrorSplashProps = {
  /** The title of the error message. It should provide a concise summary of the error. */
  errorTitle: string
  /** A detailed description of the error. This message should provide additional information or instructions for the user. */
  errorDescription: string | ReactNode
  /** Specifies the size of the error splash screen. */
  size: Size
  /** Specifies the type of icon to be displayed with the error message. The available options are listed in the icon particles. */
  iconType: IconType
  /** Specifies the variant of the error splash screen. */
  variant: Variant
}

/**
 * The `ErrorSplash` component is a component that displays an error splash screen.
 * It is typically used to inform users that something went wrong and that the developers are working on fixing the issue and/or actions for the user to take.
 * This component is customizable and allows you to provide various information and visual elements to enhance the user experience.
 */
export const ErrorSplash = ({
  errorTitle,
  errorDescription,
  size,
  iconType,
  variant = 'info',
}: ErrorSplashProps) => {
  return (
    <div
      className={cx(
        'flex h-full w-full grow flex-col items-center justify-center',
        {
          'bg-neutral-0': size === 'large',
        }
      )}
    >
      <div
        className={cx(
          'flex items-center justify-center',
          getDimensions(size),
          getBackground(variant, size),
          {
            'rounded-lg': size === 'large',
            'rounded-full': size === 'small',
          }
        )}
      >
        <Icon
          size={size}
          type={iconType}
          color={
            size === 'large' ? 'eq-color-neutral-0' : 'eq-color-neutral-400'
          }
        />
      </div>
      <h1
        className={cx('text-center text-neutral-900', {
          'headline-l-strong mt-32': size === 'large',
          'body-strong mt-8': size === 'small',
        })}
        data-testid="error-splash-title"
      >
        {errorTitle}
      </h1>
      <p
        className={cx('body text-center text-neutral-600', {
          'mt-24': size === 'large',
          'mt-8': size === 'small',
        })}
        data-testid="error-splash-description"
      >
        {errorDescription}
      </p>
    </div>
  )
}
