import { iconTypes } from '@cais-group/equity/particles/icons'
import { Carousel } from '@cais-group/homepage/ui/components'
import type { ContentDataType } from '@cais-group/homepage/util/types'
import {
  Card,
  CardProps,
  TextCard,
  prepareBasicCardProps,
} from '@cais-group/shared/ui/contentful/card'

import { DEFAULT_BACKGROUND, DEFAULT_LAYOUT } from '../constants'
import { TextCardProps, prepareTextComponentCardProps } from '../helper'
import { ComponentSectionProps } from '../types'

import { CollapsibleSectionContainer } from './collapsible-section-container'
import { SectionHeader } from './section-header'
import { TextContainer } from './text-container'

export function ComponentSection(props: ComponentSectionProps) {
  const {
    componentId,
    componentSectionDescription,
    customContent,
    itemsCollection,
    title,
  } = props
  const items = itemsCollection?.items

  /**
   * Note: Find the Custom-Content first and give it more control.
   * May need to lift this to the parent component to give it full control.
   * */

  return (() => {
    switch (componentId) {
      case 'Custom-Content-1':
      case 'Custom-Content-2':
      case 'Custom-Content-3':
      case 'Custom-Content-4': {
        const Component = customContent?.[componentId]
        return Component ? (
          <Component
            description={componentSectionDescription}
            items={items}
            title={title}
          />
        ) : null
      }
      default:
        return items ? (
          <>
            <SectionHeader
              title={title}
              componentSectionDescription={componentSectionDescription}
            />
            {(() => {
              switch (componentId) {
                case 'Accordions':
                  return <CollapsibleSectionContainer items={items} />
                case '2-col':
                  return (
                    <TextContainer
                      items={items}
                      options={{
                        layout: DEFAULT_LAYOUT,
                        backgroundColor: DEFAULT_BACKGROUND,
                      }}
                    />
                  )
                case 'Carousel': {
                  return (
                    <Carousel
                      items={items}
                      // TODO HP-431 - Is there a better way to handle setting size of card?
                      itemMinWidth={items.length > 3 ? 'medium' : 'large'}
                      renderItem={({ item }) => {
                        if (item?.__typename === 'ComponentText') {
                          const props = prepareTextComponentCardProps({
                            ...item,
                            endAdornment: iconTypes.ArrowRight,
                          } as TextCardProps)
                          return <TextCard {...props} variant="text" />
                        } else {
                          const props = item
                            ? prepareBasicCardProps<CardProps>(
                                item as ContentDataType
                              )
                            : null
                          return props ? (
                            <Card {...props} date={undefined} />
                          ) : null
                        }
                      }}
                      testId="landing-page"
                    />
                  )
                }
                case '1-col':
                  return (
                    <TextContainer
                      items={items}
                      options={{
                        layout: '1col',
                        backgroundColor: DEFAULT_BACKGROUND,
                      }}
                    />
                  )
                case 'Wave-Banner':
                  // import { WaveBanner } from '@cais-group/equity/atoms/wave'
                  return <p>[Wave banner]</p>
                default: {
                  return null
                }
              }
            })()}
          </>
        ) : null
    }
  })()
}
